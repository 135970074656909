.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-expansion-indicator::after {
  border-color: #144766;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #144766;
  --mdc-radio-selected-hover-icon-color: #144766;
  --mdc-radio-selected-icon-color: #144766;
  --mdc-radio-selected-pressed-icon-color: #144766;
  --mat-mdc-radio-checked-ripple-color: #144766;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #144766;
  --mdc-checkbox-selected-hover-icon-color: #144766;
  --mdc-checkbox-selected-icon-color: #144766;
  --mdc-checkbox-selected-pressed-icon-color: #144766;
  --mdc-checkbox-unselected-focus-icon-color: #144766;
  --mdc-checkbox-unselected-hover-icon-color: #144766;
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #144766;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #144766;
  width: 100%;
  margin: 2rem auto;
}

.mat-mdc-progress-spinner.white {
  --mdc-circular-progress-active-indicator-color: #fff;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #144766;
  --mdc-switch-selected-handle-color: #144766;
  --mdc-switch-selected-hover-state-layer-color: #144766;
  --mdc-switch-selected-pressed-state-layer-color: #144766;
  --mdc-switch-selected-focus-handle-color: #144766;
  --mdc-switch-selected-hover-handle-color: #144766;
  --mdc-switch-selected-pressed-handle-color: #144766;
  --mdc-switch-selected-focus-track-color: #668295;
  --mdc-switch-selected-hover-track-color: #668295;
  --mdc-switch-selected-pressed-track-color: #668295;
  --mdc-switch-selected-track-color: #668295;
}

.mat-horizontal-stepper-header {
  padding: 0px 12px !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: #144766;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #c2d1dc;
  --mdc-chip-label-text-color: #144766;
}

.mdc-tab__text-label {
  color: rgb(20 71 102) !important;
}

.mat-mdc-tab.mdc-tab {
  margin: 0.5rem !important;
  border-radius: 0.5rem !important;
  min-width: 15% !important;
}

.mdc-tab--active .mdc-tab-indicator--active {
  background: red;
}

.mat-mdc-tab.mdc-tab:hover {
  background: #dfdfdf !important;
  color: black;
}

::ng-deep .mat-ink-bar {
  background-color: #ee2925 !important;
}

.mdc-tab-indicator__content--underline {
  border-color: rgb(20 71 102) !important;
  border-width: 5px;
  border-radius: 0.5rem !important;
  background-color: red !important;
}

::ng-deep .mat-sort-header-container {
  display: flex;
  justify-content: center;
}
