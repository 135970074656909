/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-wrapper {
    @apply flex h-12 w-full rounded border border-gray-700 px-4 py-2.5;
  }

  .input-base {
    @apply w-full outline-none;
  }

  .float-label-select {
    @apply pointer-events-none absolute -top-2.5 left-2 bg-white px-2 text-sm font-medium	text-dark-blue-puple transition-all duration-150 peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-dark-blue-puple peer-focus:-top-2.5 peer-focus:text-sm peer-focus:font-medium peer-focus:text-dark-blue-puple;
  }

  .float-label-input {
    @apply pointer-events-none absolute -top-2.5 left-2 bg-white px-2 text-sm font-medium	text-dark-blue-puple transition-all duration-150 peer-placeholder-shown:top-[0.70rem] peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-dark-blue-puple peer-focus:-top-2.5 peer-focus:text-sm peer-focus:font-medium peer-focus:text-dark-blue-puple;
  }

  .base-btn {
    @apply flex flex-row items-center justify-center gap-4 rounded px-6 py-2.5 transition-all duration-150 hover:opacity-90 disabled:hover:opacity-100;
  }

  .button-icon {
    @apply flex items-center rounded-full px-2 py-1 hover:bg-gray-200;
  }

  .button-icon-disable {
    @apply flex items-center p-2 text-gray-400;
  }

  .span-required {
    @apply text-sm text-red-600;
  }

  .budget-status {
    @apply my-1 flex w-full justify-center rounded p-1 text-center text-xs text-white;
  }

  .button-close {
    @apply mr-4 w-14  rounded border bg-dark-blue-puple text-center text-white  hover:cursor-pointer hover:opacity-90;
  }

  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }
}
